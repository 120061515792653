
.table-scroll {
    overflow: auto; /* Enable scrolling for this section */
 
}

.responsive-container {
  
    padding: 20px;
    overflow: auto !important;
  }

  .table-wrapper {
    overflow-x: auto; /* Enable horizontal scroll */
    overflow-y: auto; /* Enable vertical scroll for table */
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full-screen loader */
  }

  /* Ensure search bar stays in place both vertically and horizontally */
  .table-wrapper .dataTables_filter {
    position: sticky;
    top: 0; /* Keep it at the top during vertical scroll */
    left: 0; /* Keep it at the left during horizontal scroll */
    z-index: 10; /* Ensure it stays above table rows */
    display: flex;
    justify-content: flex-start; /* Default alignment to the left */
    background-color: white; /* Prevent overlap issues */
    padding: 10px;
    border-bottom: 1px solid #ccc; /* Add a bottom border for clarity */
    width: 100%; /* Ensure it stretches across the container */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  }

  /* Responsive styles for mobile */
  @media (max-width: 768px) {
    .responsive-container {
      padding: 10px;
    }

    .table-wrapper {
      font-size: 12px; /* Adjust font size for smaller screens */
    }

    .table-wrapper .dataTables_filter {
      justify-content: flex-end; /* Align to the right on mobile screens */
    }
  }

  @media (max-width: 480px) {
    .nameAr {
justify-content: flex-start !important; /* Align to the right on mobile screens */
}
    .responsive-container {
      text-align: right; /* Align content to the right */

    }

    .table-wrapper {
      font-size: 10px; /* Further reduce font size for very small screens */
      
    }

    .table-wrapper .dataTables_filter {
      justify-content: flex-end; /* Ensure right alignment for search bar */
    }

    .table-wrapper th,
    .table-wrapper td {
      text-align: right !important; /* Align table headers and cells to the right */
    }

    .table-wrapper .dataTables_wrapper .dataTables_paginate {
      justify-content: flex-end; /* Align pagination to the right */
    }

    .table-wrapper tbody tr td p {
      text-align: right !important; /* Explicitly align table body cells to the right */
    }
      .table-wrapper tbody tr td p {
      text-align: right !important; /* Explicitly align table body cells to the right */
    }
      tbody {
       text-align: right !important;
      }

    tbody tr {
    text-align: right !important;
    }

    .table-wrapper tbody tr td div p {
      justify-content: flex-end; /* Align any div content inside cells to the right */
    }
  }